<template>
  <div>
    <div class="table-page-search-wrapper" v-if="true">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入" allowClear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.number" placeholder="请输入" allowClear/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
      <a-button type="primary" icon="export" @click="handleExport" v-if="true">导出</a-button>
    </div>

    <s-table
      bordered
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 1500}"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="used_rate" slot-scope="text, record">
        <span :style="activation((record.used * 100 / record.count_total).toFixed(2))">{{ Math.abs(record.count_total) < 1 ? '-' : (record.used * 100 / record.count_total).toFixed(2)+' %' }}</span>
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right;">
        {{ text.toFixed(2) }}
      </div>
      <div slot="money_render" slot-scope="text" style="text-align: right;">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleProjectItem(record)" v-action:query>查看</a>
          <a-divider type="vertical" v-if="record.status === 1" />
          <a @click="handleEdit(record)" v-if="record.status === 1" v-action:edit>编辑</a>
          <a-divider type="vertical" v-if="record.status === 1" />
          <a @click="handleStatus(record)" v-if="record.status === 1" v-action:edit>归档</a>
          <a-divider type="vertical" v-if="record.status === 1"/>
          <a-popconfirm
            v-if="record.status === 1"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-action:delete
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import {
  property_project_list,
  property_project_export,
  property_project_create,
  property_project_delete, property_project_update
} from '@/api/property_project'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      property_bill_visible: false,
      rent_bill_visible: false,
      corporation_id: null,
      tip: '',
      corList: [],
      bookLoading: false,
      billLoading: false,
      billTime: {},
      content: '',
      loading: false,
      confirmLoading: false,
      confirmPropertyBillLoading: false,
      confirmRentBillLoading: false,
      confirmAttachmentLoading: false,
      preLoading: false,
      dictionaries: Dictionaries,
      visibleAttachment: false,
      attachmentListItemId: 0,
      // 创建窗口控制
      confirmImagesLoading: false,
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          fixed: 'left',
          width: 50
        },
        {
          title: '名称',
          dataIndex: 'name',
          fixed: 'left',
          width: 150,
          ellipsis: true
        },
        {
          title: '编号',
          dataIndex: 'number',
          width: 130,
          ellipsis: true
        },
        {
          title: '预算(含税)',
          dataIndex: 'budget',
          scopedSlots: { customRender: 'money_render' },
          align: 'center',
          width: 130
        },
        {
          title: '北区',
          children: [
            {
              title: '自营',
              dataIndex: 'north_self',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'north_self'
            },
            {
              title: '管委会',
              dataIndex: 'north_gov',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'north_gov'
            },
            {
              title: '济高',
              dataIndex: 'north_jg',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'north_jg'
            }
          ]
        },
        {
          title: '南区',
          children: [
            {
              title: '自营',
              dataIndex: 'south_self',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'south_self'
            },
            {
              title: '管委会',
              dataIndex: 'south_gov',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'south_gov'
            },
            {
              title: '济高',
              dataIndex: 'south_jg',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'south_jg'
            }
          ]
        },
        {
          title: '预算(不含税)',
          dataIndex: 'ex_tax_budget',
          scopedSlots: { customRender: 'money_render' },
          align: 'center',
          width: 130
        },
        {
          title: '北区(不含税)',
          children: [
            {
              title: '自营',
              dataIndex: 'ex_tax_north_self',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'ex_tax_north_self'
            },
            {
              title: '管委会',
              dataIndex: 'ex_tax_north_gov',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'ex_tax_north_gov'
            },
            {
              title: '济高',
              dataIndex: 'ex_tax_north_jg',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'ex_tax_north_jg'
            }
          ]
        },
        {
          title: '南区(不含税)',
          children: [
            {
              title: '自营',
              dataIndex: 'ex_tax_south_self',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'ex_tax_south_self'
            },
            {
              title: '管委会',
              dataIndex: 'ex_tax_south_gov',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'ex_tax_south_gov'
            },
            {
              title: '济高',
              dataIndex: 'ex_tax_south_jg',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              width: 130,
              key: 'ex_tax_south_jg'
            }
          ]
        },
        {
          title: '开始日期',
          align: 'center',
          width: 130,
          dataIndex: 'start_date'
        },
        {
          title: '结束日期',
          align: 'center',
          width: 130,
          dataIndex: 'end_date'
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.property_project_check_status[text] || '',
          ellipsis: true,
          align: 'center',
          width: 60
        },
        {
          title: '钉钉审核',
          align: 'center',
          width: 130,
          dataIndex: 'is_open_dingding',
          customRender: (text) => text ? '开启' : '关闭'
        },
        {
          title: '科目',
          dataIndex: 'subject',
          customRender: (text) => this.$Dictionaries.property_project_fee_subject[text] || '',
          ellipsis: true,
          align: 'center',
          width: 150
        },
        {
          width: 220,
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return property_project_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    showDrawer () {
      this.visibleDrawer = true
    },
    onClose () {
      this.visibleDrawer = false
    },
    getFileSuffix (filename) {
      console.log(filename, '787800')
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    getFileName (filename) {
      let name = filename.substring(filename.lastIndexOf('/') + 1)
      const suffix = filename.substring(filename.lastIndexOf('.')).toLowerCase()
      console.log(name, name.length)
      name = name.length - suffix.length > 10 ? name.substring(0, 5) + '...' + name.substring(8, 10) + suffix : name
      return name
    },
    getFileFullName (filename) {
      return filename.substring(filename.lastIndexOf('/') + 1)
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleExport () {
      property_project_export(this.queryParam).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `项目.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleImagesCancel () {
      this.images_visible = false
      const form = this.$refs.createImagesModal.form
      form.resetFields() // 清理表单数据（可不做）
      this.$refs.table.refresh()
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleProjectItem (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push({ path: '/property_project/' + record.id, query: { record: record.name, item_id: -1, is_open_dingding: record.is_open_dingding ? 1 : 0, status: record.status, subject: record.subject } })
    },
    handleDelet (record) {
      property_project_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleStatus (record) {
      property_project_update(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleEdit (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // 编辑
      this.$router.push({ path: '/property_project/index/' + record.id })
    },
    getQuarter (month) {
      if (month >= 1 && month <= 3) {
        return 1
      } else if (month >= 4 && month <= 6) {
        return 2
      } else if (month >= 7 && month <= 9) {
        return 3
      } else {
        return 4
      }
    },
    exTaxInTaxCheck (tax, ex_tax) {
      if (ex_tax > tax) {
        return true
      } else {
        return false
      }
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          values.status = 1
          values.budget = Math.round(values.budget * 100)
          values.north_self = Math.round(values.north_self * 100)
          values.south_self = Math.round(values.south_self * 100)
          values.north_jg = Math.round(values.north_jg * 100)
          values.south_jg = Math.round(values.south_jg * 100)
          values.north_gov = Math.round(values.north_gov * 100)
          values.south_gov = Math.round(values.south_gov * 100)

          values.ex_tax_budget = Math.round(values.ex_tax_budget * 100)
          values.ex_tax_north_self = Math.round(values.ex_tax_north_self * 100)
          values.ex_tax_south_self = Math.round(values.ex_tax_south_self * 100)
          values.ex_tax_north_jg = Math.round(values.ex_tax_north_jg * 100)
          values.ex_tax_south_jg = Math.round(values.ex_tax_south_jg * 100)
          values.ex_tax_north_gov = Math.round(values.ex_tax_north_gov * 100)
          values.ex_tax_south_gov = Math.round(values.ex_tax_south_gov * 100)

          if (values.budget !== (values.north_jg + values.north_self + values.north_gov + values.south_jg + values.south_self + values.south_gov)) {
            this.$message.warning('预算(含税)金额与分配金额不匹配')
            this.confirmLoading = false
            return
          }
        if (values.ex_tax_budget !== (values.ex_tax_north_jg + values.ex_tax_north_self + values.ex_tax_north_gov + values.ex_tax_south_jg + values.ex_tax_south_self + values.ex_tax_south_gov)) {
            this.$message.warning('预算(不含税)金额与分配金额不匹配')
            this.confirmLoading = false
            return
          }
        const is_ok = this.exTaxInTaxCheck(values.budget, values.ex_tax_budget) || this.exTaxInTaxCheck(values.north_self, values.ex_tax_north_self) ||
          this.exTaxInTaxCheck(values.north_gov, values.ex_tax_north_gov) || this.exTaxInTaxCheck(values.north_jg, values.ex_tax_north_jg) ||
          this.exTaxInTaxCheck(values.south_gov, values.ex_tax_south_gov) || this.exTaxInTaxCheck(values.south_jg, values.ex_tax_south_jg) ||
          this.exTaxInTaxCheck(values.south_self, values.ex_tax_south_self)
          if (is_ok) {
            this.$message.warning('含税金额不能小于不含税金额')
            this.confirmLoading = false
            return
          }

          if (values.start_date && values.end_date) {
            // 判断开始时间和结束时间是否在一个季度中
            const start_date_year = new Date(values.start_date).getFullYear()
            const start_date_month = new Date(values.start_date).getMonth() + 1
            const end_date_year = new Date(values.end_date).getFullYear()
            const end_date_month = new Date(values.end_date).getMonth() + 1

            if (start_date_year === end_date_year) {
              if (end_date_month < start_date_month) {
                const msg = '开始时间大于结束时间'
                this.$message.warning(msg)
                this.confirmLoading = false
                return
              } else if (this.getQuarter(start_date_month) !== this.getQuarter(end_date_month)) {
                const msg = '开始时间和结束时间不在同一个季度'
                this.$message.warning(msg)
                this.confirmLoading = false
                return
              }
            } else {
              this.$message.warning('开始时间和结束时间不在同一个季度')
              this.confirmLoading = false
              return
            }
          }
          property_project_create(values).then(res => {
            this.visible = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('创建成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  },
  computed: {
    activation () {
      return (used_rate) => {
        if (!Number.isFinite(Number(used_rate))) {
          return { 'color': 'red' }
        }
        if (used_rate > 85) {
          return { 'color': 'Green' }
        } else if (used_rate > 50) {
          return { 'color': 'Orange' }
        } else {
         return { 'color': 'red' }
        }
      }
    }
  }
}
</script>
