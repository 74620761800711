<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="名称">
              <a-input
                v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="开始日期">
              <a-date-picker v-decorator="['start_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]" valueFormat="YYYY-MM-DD" />
            </a-form-item>
            <a-form-item label="科目" :labelCol="this.formLayout.labelCol" :wrapperCol="this.formLayout.wrapperCol">
              <a-select
                v-decorator="[
                    'subject',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                placeholder="选择"
              >
                <a-select-option
                  v-for="(val, key) in this.$Dictionaries.property_project_fee_subject"
                  :value="key"
                  :key="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="预算(含税)">
              <a-input-number
                v-decorator="['budget', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区自营(含税)">
              <a-input-number
                v-decorator="['north_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区管委会(含税)">
              <a-input-number
                v-decorator="['north_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区济高(含税)">
              <a-input-number
                v-decorator="['north_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区自营(含税)">
              <a-input-number
                v-decorator="['south_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区管委会(含税)">
              <a-input-number
                v-decorator="['south_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区济高(含税)">
              <a-input-number
                v-decorator="['south_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="编号">
              <a-input
                v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item label="结束日期">
              <a-date-picker
                v-decorator="['end_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                valueFormat="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="钉钉审核">
              <a-switch v-decorator="['is_open_dingding', {initialValue: true, valuePropName: 'checked' }]" />
            </a-form-item>
            <a-form-item label="预算(不含税)">
              <a-input-number
                v-decorator="['ex_tax_budget', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区自营(不含税)">
              <a-input-number
                v-decorator="['ex_tax_north_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区管委会(不含税)">
              <a-input-number
                v-decorator="['ex_tax_north_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="北区济高(不含税)">
              <a-input-number
                v-decorator="['ex_tax_north_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区自营(不含税)">
              <a-input-number
                v-decorator="['ex_tax_south_self', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区管委会(不含税)">
              <a-input-number
                v-decorator="['ex_tax_south_gov', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
            <a-form-item label="南区济高(不含税)">
              <a-input-number
                v-decorator="['ex_tax_south_jg', {initialValue: 0, rules: [{required: false, message: '必填项，请填写信息'}]}]"
                placeholder="请输入"
                style="width: 180px;"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this)
    }
  }
}
</script>
